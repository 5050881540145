<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="2">
          <CInput v-model="invoice_no" placeholder="Invoice no."/>
        </CCol>
        <CCol lg="2">
          <v-select 
            label="vendor_name" 
            :options="vendorList.data"
            :reduce="item => item.id"
            v-model="vendor_id"
            placeholder="Trucker"
          > 
          </v-select>
        </CCol>
        <CCol lg="2">
          <v-select 
            label="setting_name" 
            :options="originList.data"
            :reduce="item => item.id"
            v-model="origin_id"
            placeholder="Origin"
          > 
          </v-select>
        </CCol>
        <CCol lg="2">
          <v-select 
            label="setting_name" 
            :options="[
              {setting_name: 'Billed', id: 'billed'},
              {setting_name: 'Unbilled', id: 'unbilled'},
              {setting_name: 'Cancelled', id: 'cancelled'}
            ]"
            :reduce="item => item.id"
            v-model="status"
            placeholder="Status"
          > 
          </v-select> 
        </CCol> 
        <CCol lg="2">
         
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <!-- <CCol lg="2">
          <Datepicker v-model="created_at_from" input-class="form-control" placeholder="Date created from" />
        </CCol>
        <CCol lg="2">
          <Datepicker v-model="created_at_to" input-class="form-control" placeholder="Date created to" />
        </CCol> -->
        <CCol lg="2">
          <Datepicker v-model="delivery_date_from" input-class="form-control" placeholder="Delivery date from" />
        </CCol>
        <!-- <CCol lg="2">
          <Datepicker v-model="delivery_date_to" input-class="form-control" placeholder="Delivery date to" />
        </CCol> -->
        <CCol lg="2">
          <Datepicker v-model="delivery_date_to" input-class="form-control" placeholder="Delivery date to" />
        </CCol>
        <!-- <CCol lg="2"></CCol> -->
        <CCol lg="2" class="" v-if="JSON.parse(window.localStorage.getItem('user_data')).depot_id == null">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select>
        </CCol>
        <CCol lg="2" class="" v-else>
          <v-select 
            disabled
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            :value="JSON.parse(window.localStorage.getItem('user_data')).depot_id"
            placeholder="Depot"
          > 
          </v-select>
        </CCol>

         <CCol lg="2">
         <CInput v-model="ref_no" placeholder="Ref no."/>
        </CCol>
          <CCol lg="2">
        
        </CCol>
         <CCol lg="2" class="float-right">
          <CButton block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import Datetime from 'vuejs-datetimepicker';
  import Datepicker from 'vuejs-datepicker';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            window,
            invoice_no: "",
            vendor_id: "",
            depot_id: "",
            origin_id: "",
            status: "",
            ref_no: "", 
            created_at_from: "",
            created_at_to: "",
            delivery_date_from: "",
            delivery_date_to: "",
            vendorList: {
            	data: []
            },
      			originList: {
      				data: []
      			},
            depotList: {data:[]}
          }
        },
      mounted() {
        this.getVendor();
        this.getOrigin();
        this.getDepot();
      },
      components: {vSelect, Datetime, Datepicker},
      methods: {
        search() {
          const data = {
            depot_id: this.depot_id,
            invoice_no: this.invoice_no,
            vendor_id: this.vendor_id,
            origin_id: this.origin_id,
            status: this.status,
            ref_no: this.ref_no,
            created_at_from: this.created_at_from,
            created_at_to: this.created_at_to,
            delivery_date_from: this.delivery_date_from,
            delivery_date_to: this.delivery_date_to,
          }
          this.$emit("depot-search-query", data)
        },
       
        clearSearch() {
          this.invoice_no = "";
          this.depot_id = "";
          this.vendor_id = "";
          this.origin_id = "";
          this.status = "";
          this.ref_no = ""; 
          this.created_at_from = ""; 
          this.created_at_to = ""; 
          this.delivery_date_from = ""; 
          this.delivery_date_to = ""; 
          const data = {
            invoice_no: this.invoice_no,
            vendor_id: this.vendor_id,
            depot_id: this.depot_id,
            origin_id: this.origin_id,
            status: this.status,
            ref_no: this.ref_no,
            created_at_from: this.created_at_from,
            created_at_to: this.created_at_to,
            delivery_date_from: this.delivery_date_from,
            delivery_date_to: this.delivery_date_to,
          }
          this.$emit("depot-search-query", data)
        },

        getVendor(){
          axios.get(config.api_path+'/reference/vendors-list',{
            params:{
              // setting_type:'maker_model'
            }
              })
        .then(response => {
          this.vendorList = response.data; 
        })

        },
       getDepot(){
        axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
            }
              })
        .then(response => {
          this.depotList = response.data; 
        })

        },

	    getOrigin(){
        axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'origin'
            }
              })
        .then(response => {
          this.originList = response.data; 
        })

        },
      }
    }
</script>

<style scoped>

</style>
