<template>
	<div>
		<BlockUI :message="defaultMsg" :html="html" v-if="isDownloading"></BlockUI>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Trucker Settlement ({{dataList.total}})</h5>
			</CCol>
	     		 <CCol lg="6">
					     <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search mr-2"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('trucker_settlement').create"
						size="sm" style="margin-right: 10px" 
						color="success" href="#/main/create_trucker_settlement" target="_blank"
						class="float-lg-right"><i class="fa fa-plus mr-2"></i>Create Trucker Settlement
					</CButton>

					<CButton 
						v-if="config.getPermission('trucker_settlement').download"
						size="sm" style="margin-right: 10px" 
						color="primary" @click="download()"
						class="float-lg-right"><i class="fa fa-download mr-2"></i>Download
					</CButton>
		      		
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>	
					
	      	
		 <CCol lg="12">  
			<div class="table-responsive-sm table-responsive-md">
	      		<CDataTable
				  :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined
					
					
			    >  
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
				</template>
			    	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem 
								v-if="config.getPermission('trucker_settlement').view" 
								@click="viewModal(item)">
								<i class="fa fa-eye" > View </i>
								</CDropdownItem>  
				            	<CDropdownItem v-if="config.getPermission('trucker_settlement').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Update </i></CDropdownItem>   
				            	<CDropdownItem v-if="config.getPermission('trucker_settlement').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem>   
				            </CDropdown>
				        </td>
			      	</template> 
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        /> 
				</div>
	      	</CCol>
	    </CRow> 
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import Loader from '../../../loader.mixin.js';

export default {
	mixins: [Loader],
	mounted(){    
		this.getData();  
	},
	data(){
		return{
			isLoading: false,
			config,
			// // approveModal: false,
			// dispatchModal: false,
			// deliverModal: false,
			// completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [],  
			isDownloading : false,




			depotName: "", 
			fields: [
				{
					key: 'invoice_no', 
					label: 'Invoice No.'
				},
				{
					key: 'created_at_label', 
					label: 'Date Created'
				},
				{
					key: 'ref_no', 
					label: 'Ref No.'
				},
				{
					key: 'delivery_dates', 
					label: 'Delivery Dates'
				},
				{
					key: 'vendor_id_label', 
					label: 'Trucker'
				},
				{
					key: 'no_of_shipmemnt', 
					label: 'No. of Trips'
				},  
				{
					key: 'origin_id_label', 
					label: 'Origin'
				},
				{
					key: 'depot_name', 
					label: 'Depot'
				},
				{
					key: 'total_amount', 
					label: 'Amount'
				},   
				{
					key: 'status', 
					label: 'Status'
				},
				{
					key: 'action', 
					label: 'Action'
				}
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				invoice_no: "",
	            vendor_id: "",
	            origin_id: "",
				depot_id: "",
	            status: "",
	            ref_no: "", 
	            created_at_from: "",
	            created_at_to: "",
	            delivery_date_from: "",
	            delivery_date_to: "",
			}, 
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: {
		download() {
			
			this.isDownloading = true;
	    	var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	}  

			var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}  
	    	if(this.filter.delivery_date_from != ""){ 
	    		this.filter.delivery_date_from = moment(this.filter.delivery_date_from).startOf('day') / 1000;
	    	}

	    	if(this.filter.delivery_date_to != ""){ 
	    		this.filter.delivery_date_to = moment(this.filter.delivery_date_to).startOf('day') / 1000;
	    		this.filter.delivery_date_to += 86399;
	    	}

	    	if(this.filter.created_at_from != ""){ 
	    		this.filter.created_at_from = moment(this.filter.created_at_from).startOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	}

	    	if(this.filter.created_at_to != ""){ 
	    		this.filter.created_at_to = moment(this.filter.created_at_to).endOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	}
			axios.post(config.api_path+'/trucker_settlement/download', {
				vendor_id,
				invoice_no: this.filter.invoice_no,
				delivery_date_from : this.filter.delivery_date_from,
				delivery_date_to : this.filter.delivery_date_to,
				created_at_from : this.filter.created_at_from,
				created_at_to : this.filter.created_at_to,
				origin_id,
				depot_id,
				status : this.filter.status,
				ref_no : this.filter.ref_no,
			})
	    	.then(response=>{
				this.isDownloading = false;
				// console.log(response)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	    	})
			.catch(err => {
				this.isDownloading = false;
			})
		},
		 getBadge (status) {
    	return status === 'billed' ? 'success'
             : status === 'cancelled' ? 'danger' : 'primary'
    },
		reloadData(){
			this.getData();  
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		}, 
		
  

		// updatePageUnderReview(data){
		// 	this.currentPageUnderReview = data;
		// 	this.getDataUnderReview();
		// },   

		// // updatePageApproved(data){
		// // 	this.currentPageApproved = data;
		// // 	this.getDataApproved();
		// // },   

		// updatePageDeclined(data){
		// 	this.currentPageDeclined = data;
		// 	this.getDataDeclined();
		// },   

		// updatePageDispatched(data){
		// 	this.currentPageDispatched = data;
		// 	this.getDataDispatched();
		// },   

		// updatePageDelivered(data){
		// 	this.currentPageDelivered = data;
		// 	this.getDataDelivered();
		// },   

		// updatePageCompleted(data){
		// 	this.currentPageCompleted = data;
		// 	this.getDataCompleted();
		// },   

		// updatePageCancelled(data){
		// 	this.currentPageCancelled = data;
		// 	this.getDataCancelled();
		// },   

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 

	    getData(){  
			this.isLoading=true;

	    	var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	}  
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}  

	    	if(this.filter.delivery_date_from != ""){ 
	    		this.filter.delivery_date_from = moment(this.filter.delivery_date_from).startOf('day') / 1000;
	    	}

	    	if(this.filter.delivery_date_to != ""){ 
	    		this.filter.delivery_date_to = moment(this.filter.delivery_date_to).startOf('day') / 1000;
	    		this.filter.delivery_date_to += 86399;
	    	}

	    	if(this.filter.created_at_from != ""){ 
	    		this.filter.created_at_from = moment(this.filter.created_at_from).startOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	}

	    	if(this.filter.created_at_to != ""){ 
	    		this.filter.created_at_to = moment(this.filter.created_at_to).endOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	}

	    	axios.get(config.api_path+'/trucker_settlement?invoice_no='+this.filter.invoice_no
			+'&vendor_id='+vendor_id
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&status='+this.filter.status
			+'&ref_no='+this.filter.ref_no
			+'&created_at_from='+this.filter.created_at_from
			+'&created_at_to='+this.filter.created_at_to
			+'&delivery_date_from='+this.filter.delivery_date_from
			+'&delivery_date_to='+this.filter.delivery_date_to
			+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
	    			value.no_of_shipmemnt = value.booking.length;
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "-"
	    			}

	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "-"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "-"
	    			}

	    			if(value.vendor_id){
	    				value.vendor_id_label = value.vendor.vendor_name
	    			}
	    			else{
	    				value.vendor_id_label = "-"
	    			}

	    			if(value.ref_no){
	    				value.ref_no = value.ref_no
	    			}
	    			else{
	    				value.ref_no = "-"
	    			}

					value.depot_name = (value.depot) ? value.depot.setting_name :'-';
 
					value.created_at_label = moment.utc(value.created_at, "YYYY-MM-DD").format('MM/DD/YYYY');
	    			value.delivery_dates = moment.unix(value.delivery_date_from).format('MM/DD/YYYY') +" - "+ moment.unix(value.delivery_date_to).format('MM/DD/YYYY')

	    			return value;
	    		}); 
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    
	    updateModal(item){ 
	    	// this.$router.push('/main/update_trucker_settlement/'+item.id) 
			window.open('#/main/update_trucker_settlement/'+item.id, '_blank');
	    },

	    viewModal(item){ 
	    	this.$router.push('/main/view_trucker_settlement/'+item.id) 
	    },

	    deleteRow(item){
	    	Swal.fire({
				icon: 'warning',
				title: 'Are you sure you want to delete this billing?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/trucker_settlement/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Trucker settlement successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    },  

	    // deleteItem(item){
	    // 	axios.delete(config.api_path+'/client_billing/'+item.id)
	    // 	.then(response => {
	    // 		Swal.fire({
		// 			title: 'Success!',
		// 			text: "Client billing successfully deleted",
		// 			icon: 'success', 
		// 		})
		// 		this.getData();	
	    // 	})
	    // }
 

 	}
}
</script>
